import { Box, Button, Typography } from "@mui/material";

const About = () => {
  const calculateAge = () => {
    return (
      new Date().getFullYear() -
      new Date(1983, 12, 12, 0, 0, 0, 0).getFullYear()
    );
  };

  return (
    <>
      
      <Box className="intersect fade fadeOut about">
        <Typography
          fontSize={"50px"}
          variant={"h1"}
          textAlign={"center"}
          mt={"50px"}
        >
          {" "}
          Om mig
        </Typography>

        <div
          className="text-section"
          style={{ display: "flex", marginTop: "50px" }}
        >
          <div>
            <img
              src={require("../Images/tsr.jpg")}
              className="profile-picture"
            ></img>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <ul>
              <li>
                <Typography>Thomas Rasmussen</Typography>
              </li>
              <li>
                <Typography>Software Udvikler</Typography>
              </li>
              <li>
                <Typography>Bor i Viborg</Typography>
              </li>
              <li>
                {" "}
                <Typography>{calculateAge()} år gammel</Typography>
              </li>
            </ul>
          </div>
        </div>
        <Typography className="text-section">
          Jeg tog datamatiker uddannelsen i 2014 efter at have arbejdet med
          salg af IT i en årrække og fordi min passion for softwareudvikling var stor. 
          Siden har jeg arbejdet i flere forskellige virksomheder
          indenfor IT udvikling - alt lige fra apps til front- og backend
          udvikling.
        </Typography>
        <Typography className="text-section">
          Jeg har som regel altid gang i et hobbyprojekt eller to ved siden af
          mit arbejde, hvilket også kan ses her på siden under projekter. Jeg har arbejdet på disse
          projekter for at styrke mine evner og lære nye teknologier, men også fordi jeg har ment 
          der har manglet en applikation som dækker lige præcis det behov. Jeg bliver meget motiveret af at udvikle applikationer 
          som andre brugere og jeg selv har glæde af.
        </Typography>
        <Typography className="text-section">
          Jeg har bl.a. erfaring med:
          <ul>
            <li>Backend udvikling (C#, ASP.NET, ASP.NET Core)</li>
            <li>Frontend udvikling (React, Angular, Javascript, CSS)</li>
            <li>Databaser (SQL, CosmosDB)</li>
            <li>Apps (Android, PWA)</li>
            <li>Azure Devops</li>
            <li>Lidt UI/UX fra diverse projekter</li>
          </ul>
        </Typography>
        <Typography className="text-section">
         Privat bor jeg i et lille hus i Viborg sammen med min kone, Jeanet, og vores 2 piger. Jeg dyrker en del motion i form af løb, badminton og vandring og nyder
         at rejse og opleve nye kulturer og lande. Derudover er jeg glad for naturen, og bruger lidt tid i haven af og til. Og lidt Lego, gaming og lignende når tid og økonomi tillader det :-)
        </Typography>
      </Box>
    </>
  );
};

export default About;
