import { useEffect, useState } from "react";
import styled from "styled-components";
import Projects from "./Projects";
import Resume from "./Resume";
import About from "./About";
import Contact from "./Contact";
import { Box, Button, useMediaQuery } from "@mui/material";

const Frontpage = () => {

  const isMobile = useMediaQuery('(max-width: 768px)')

  useEffect(() => {
    const observerOptions = {
      root: null, // Null = based on viewport
      rootMargin: "0px", // Margin for root if desired
      threshold: 0.1, // Percentage of visibility needed to execute function
    };

    function observerCallback(entries: any, observer: any) {
      //@ts-ignore
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.replace("fadeOut", "fadeIn");
        } else {
          entry.target.classList.replace("fadeIn", "fadeOut");
        }
      });
    }

    const fadeElements = document.querySelectorAll(".fade");

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    fadeElements.forEach((el) => observer.observe(el));
  }, []);

  

  return (
    <FrontpageWrapper isMobile={isMobile}>
      <About />
      <Resume />
      <Projects />
      <Contact />

      <Footer />
    </FrontpageWrapper>
  );
};

const Footer = ()=> {

  return <Box
        style={{
          position: "fixed",
          bottom: "0",
          width: "100vw",
          height: "40px",
          backgroundColor: "white",
          zIndex: "500",
        }}
      >
        <Button
          onClick={() => {
            document
              .querySelector(".about")
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Om mig
        </Button>
        <Button
          onClick={() => {
            document
              .querySelector(".history")
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Mit CV
        </Button>
        <Button
          onClick={() => {
            document
              .querySelector(".projects")
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Projekter
        </Button>
        <Button
          onClick={() => {
            document
              .querySelector(".contact")
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Kontakt
        </Button>
      </Box>

}

const FrontpageWrapper = styled.div<{isMobile:boolean}>`

  .MuiTimelineItem-root{
    margin-left: ${props=> props.isMobile ? '-80px' : '0'};
  }

  .profile-picture {
    border-radius: 90px;
  }

  .text-section {
    max-width: ${props=> props.isMobile ? '90vw' : '50vw'};
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: left;
    line-height: 28px;
  }

  .fade {
    height: auto;
    min-height: 70vh;
    max-width:${props=> props.isMobile ? '95vw' : '60vw'};
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow: auto;
    background-color: #fbfbfb;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 0.7s ease-in-out;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 20px;
  }

  .fadeOut {
    opacity: 0;
  }

  .fadeIn {
    opacity: 1;
  }

  .down {
    transform: translateY(50px);
    transition: 0.5s all;
  }

  .up {
    transform: translateY(0);
    transition: 0.5s all;
  }
`;

export default Frontpage;
