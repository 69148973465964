export class ProjectModel{

    title:string = "";
    description:string = "";
    url:string = "";
    logo:string = "";
    detailedDescription = "";

    constructor(title:string, description:string, url:string, logo:string, detailedDescription:string){
        this.title = title;
        this.description = description;
        this.url = url;
        this.logo = logo;
        this.detailedDescription = detailedDescription;
    }
  
}