import { IconButton, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

const Contact = () => {
  return (
    <Box className="intersect fade fadeOut contact" sx={{minHeight:'40vh !important'}}>
      <Typography
        fontSize={"50px"}
        variant={"h1"}
        textAlign={"center"}
        mt={"50px"}
        mb={"50px"}
      >
        {" "}
        Kontakt
      </Typography>

      <Typography>Du kan finde mig på nedenstående sociale medier eller sende mig en direkte mail.</Typography>
      <Typography variant="body2" mt={'30px'} mb={'30px'} color="text.secondary">
       Har du en kommentar til et af projekterne, er du velkommen til at kontakte mig. Idéer til nye projekter modtages også gerne :-)
      </Typography>
      <Box mt={"50px"} display="flex" justifyContent={"center"} alignItems="center">
            
            <IconButton size="large" onClick={()=> { window.open('https://www.linkedin.com/in/thomasrasmussen1983/', '_blank')}}>
                    <LinkedInIcon fontSize="large"/>
            </IconButton>
            <IconButton size="large" onClick={()=> { window.open('https://www.facebook.com/thomassn', '_blank')}}>
                    <FacebookIcon fontSize="large"/>
            </IconButton>
            <Typography>
                <Link href="mailto:mail@thomasrasmussen.dk">mail@thomasrasmussen.dk</Link>
            </Typography>
      </Box>
    </Box>
  );
};

export default Contact;
