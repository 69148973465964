import { Timeline, timelineItemClasses, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from "@mui/lab";
import { Box, Typography } from "@mui/material";

const Resume = () => {
  return (
    <Box className="intersect fade fadeOut history">
      <Typography
        fontSize={"50px"}
        variant={"h1"}
        textAlign={"center"}
        mt={"50px"}
      >
        Mit CV
      </Typography>
      <Box>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
              ml: "100px",
            },
          }}
          
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              Mjølner Informatics
              <TimelineContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
                Software Udvikler med fokus på front- og backend applikationer,
                primært til Norlys' energidivision.
              </TimelineContent>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              2018 - 2022, Norlys
              <TimelineContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
                Udvikling af diverse interne værktøjer og applikationer til
                bl.a. energi- og teledivisionen. Både front- og backend udvikling.
              </TimelineContent>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              2016 - 2018, ITXPress
              <TimelineContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
                Udvikling af Windows Phone app og hjemmesideløsninger til bl.a.
                landbrugsvirksomheder.
              </TimelineContent>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              2015 - 2016, Freeway
              <TimelineContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
                Udvikling af hjemmesider
              </TimelineContent>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
        <Typography variant="body2"  color="text.secondary" className="text-section">
          Foruden mine stillinger, har jeg undervejs arbejdet på diverse
          sideprojekter, bl.a. min netradio portal netradio.nu som stadig er
          meget brugt. Læs mere om dette længere nede.
        </Typography>
      </Box>
    </Box>
  );
};

export default Resume;
