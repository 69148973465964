import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ProjectModel } from "../Models/ProjectModel";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import styled from "styled-components";

const Projects = () => {
  const [projects, setProjects] = useState<Array<ProjectModel>>();
  const [flippedProjects, setFlippedProjects] = useState<Array<string>>([""]);

  useEffect(() => {
    let arr = new Array<ProjectModel>();
    arr.push(
      new ProjectModel(
        "Netradio.nu",
        "En dansk netradio portal hvor du kan streame alle de mest  populære, danske stationer.",
        "https://www.netradio.nu",
        "netradionu.png",
        `Netradio.nu blev lanceret tilbage i 2016. Bygget i ren HTML, CSS og en del AngularJS og sidenhen opgraderet til nyere version af Angular.      
        <br><br>
        Benytter mit eget ASP.NET WebAPI som backend, bl.a. til indlæsning af stationer, persistering af indstillinger og meget mere.
        <br><br>
        Er stadig rimelig velbesøgt, med en 500-1000 daglige besøgende. Jeg har også udviklet en app og Chrome udvidelse til Netradio.nu.
        `
      )
    );

    arr.push(
      new ProjectModel(
        "Ugensmad.nu",
        "Opret madplaner og indkøbslister og brug dem online på din pc/mobil eller print dem ud.",
        "https://www.ugensmad.nu",
        "ugensmad.png",
        `
          Min motivation med dette projekt var at jeg manglede et simpelt værktøj til at oprette ugentlige madplaner og indkøbslister, og derefter lavede jeg denne side.
          <br><br />
          Er bygget i HTML, CSS, Angular og nenytter mit eget ASP.NET WebAPI som backend til bl.a. at gemme indkøbslister og madplaner.
          <br><br />
          Siden er ikke så kendt, og det er stort kun mig selv som bruger det.
        `
      )
    );

    arr.push(
      new ProjectModel(
        "KalenderNet.dk",
        "En online kalender hvor du nemt og overskueligt kan håndtere dine og familiens aftaler. Understøtter gentagende aftaler, påmindelser, deling af kalender og meget andet.",
        "https://www.kalendernet.dk",
        "kalendernet.png",
        `Et af mine større projekter hvor jeg selv har opbygget en komplet kalender fra bunden. Bygget i HTML, CSS, Angular og en masse kald til mit eget WebAPI, bl.a. til at gemme aftaler, deling af kalendere og udsendelse af påmindelser.
        <br><br>
        Har et lille antal faste brugere hver dag, som dog har været støg stigende.
        `
      )
    );

    // arr.push(
    //   new ProjectModel(
    //     "Weblist.dk",
    //     "Lav to-do lister og kanban boards nemt og hurtigt. Understøtter påmindelser, deling af lister, arkivering og mere. Er bygget til både pc og mobil, og som PWA (kan installeres som app).",
    //     "https://www.weblist.dk",
    //     "weblist.png",
    //     `Et af mine største projekter opbygget i React frem for Angular. Mens dette skrives (marts 2023) udvikler jeg stadig løbende på dette projekt.
    //     <br><br>
    //     Der er brugt HTML, Javascript, React, React MUI og Styled Components til dette projekt. Benytter mit nye NET Core WebAPI til datapersistering.
    //     <br><br>
    //     Er bygget som en PWA, og siden kan dermed installeres på PC og mobil som en app.
    //     `
    //   )
    // );

    arr.push(
      new ProjectModel(
        "Gratisbankoplader.dk",
        "En lille, gratis service jeg har lavet, hvor du kan generere alle de bankoplader du har brug for.",
        "https://www.gratisbankoplader.dk",
        "",
        `Har du brug for at printe eller downloade dine egne bankoplader? Så værsgo. Print alle de bankoplader du har brug for, nemt og hurtigt. Mulighed for at printe direkte fra siden eller downloade pladerne, samt mulighed for at vælge mellem et par temaer til pladerne, hvis du synes de har brug for at blive peppet lidt op.
        <br><br>
        Rent teknisk er siden bygget i React som et rent frontend projekt, ved hjælp af Typescript, Styled Components og MUI som design.
        `
      )
    );

    // arr.push(
    //   new ProjectModel(
    //     "Mikrolink.dk",
    //     "En service til at forkorte links og se historik over forkortede links hvis du er logget ind",
    //     "https://mikrolink.dk/",
    //     "Mikrolink.png",
    //     `Et simpel service til at forkorte links og holde styr på dem online.
    //     <br><br>
    //     Er bygget i HTML, CSS, React og Styled Components og med kald mod mit eget WebApi.
    //     `
    //   )
    // );

    arr.push(
      new ProjectModel(
        "NinjaCat",
        "Et simpelt, lille spil hvor du skal overleve ved så længe som muligt, ved at skyde ninjastjerner efter fjender. Prøv det selv ved at besøge siden.",
        "http://www.ninjacat.thomasrasmussen.dk",
        "Ninjacat.png",
        `
          Du styrer ved at bruge WASD tasterne og skyder ved hjælp af musen. Tryk ENTER for at smide en bombe som kan dræbe flere fjender.
          Af og til kommer der hjælp i form af kasser med ekstra liv eller flere bomber. De store fjender (bosserne), skal skydes 3 gange for at dø.
          <br><br>
          Dette spil var mit første forsøg på at lave et spil bygget i ren Javascript, HTML og Canvas. Det er ikke bygget til mobil.
          `
      )
    );

    arr.push(
      new ProjectModel(
        "NewsSearch.dk",
        "En søgeside hvor du kan søge efter nyheder fra mange forskellige sider.",
        "https://www.newssearch.dk",
        "newssearch.svg",
        `
           Søg efter nyheder fra mange af de mest populære, danske nyhedssider via. NewsSearch.dk. Du kan desuden oprettte favoritter, som er søgeord hvor der automatisk tjekkes for nyheder som indeholder dette ord, én gang i timen.
           Se liste over alle fundne nyheder under historik siden, hvor du også kan markere nyheder som favoritter eller slette dem fra listen.
            `
      )
    );

    arr.push(
      new ProjectModel(
        "StemOmDet.dk",
        "Opret dine egne afstemninger på få sekunder og tilpas så de matcher dine behov.",
        "https://www.stemomdet.dk",      
        "StemOmDet.png",
        `Vælg afstemningstype og svarmuligheder og du får et unikt link til din egen afstemning.
        <br><br>
        Mange muligheder for at tilpasse afstemninger, bl.a. kan du medsende billeder til svarmuligheder, lave udløbsdato, mulighed for at kommentere inde på afstemning, give afstemning dit eget unikke ID og mere.
        <br><br>
        Projektet er bygget i React og der bruges React MUI som komponentbibliotek. Projektet bruger mit eget C# Minimal API som backend.
        `
        
      )
    );

    setProjects(arr);
  }, []);

  const isFlipped = (item: ProjectModel) => {
    return flippedProjects.indexOf(item.title) > -1;
  };

  return (
    <Box className="fade fadeOut projects">
      <Typography
        fontSize={"50px"}
        variant={"h1"}
        textAlign={"center"}
        mt={"50px"}
        mb={"50px"}
      >
        {" "}
        Mine projekter
      </Typography>
      <Typography m={'12px 24px'}>
        En oversigt over nogle af de hobbyprojekter jeg har arbejdet på og som stadig er aktive. Udover disse sider, har jeg en del andre projekter som ikke længere er aktive og online.
      </Typography>

      <ProjectWrapper>
        <Box display={"flex"} flexWrap={"wrap"} justifyContent={"center"}>
          {projects?.map((item) => {
            return (
              <div className="flip-card">
              
                <div
                  className={`flip-card-inner ${
                    isFlipped(item) ? "flipped" : ""
                  }`}
                >
                  <div className="flip-card-front">
                    <Card
                      sx={{
                        width: 300,
                        position: "relative",
                        minHeight: "300px",
                        ":hover": {
                          boxShadow: 5, // theme.shadows[20]
                        },
                      }}
                    >
                      <CardContent sx={{ minHeight: "75%" }}>
                        <CardHeader>{item.title}</CardHeader>
                        <img
                          loading="lazy"
                          width={"60px"}
                          src={require(`../Images/projects/${
                            item.logo?.length > 0 ? item.logo : "tsrlogo.png"
                          }`)}
                        ></img>

                        <Typography variant="body2" textAlign={"left"}>
                          {item.description}
                          {item.title === "Netradio.nu" && (
                            <div style={{ marginTop: "5px" }}>
                              Kan også hentes som{" "}
                              <a
                                href="https://play.google.com/store/apps/details?id=netradio.nu"
                                target={"_blank"}
                                title="Netradio.nu app"
                              >
                                Android app
                              </a>{" "}
                              og{" "}
                              <a
                                href="https://chrome.google.com/webstore/detail/netradionu/ngbeminkgfnpklfpkmlpnhlogejpdblg?utm_source=chrome-ntp-icon"
                                target={"_blank"}
                                title="Netradio.nu Chrome udvidelse"
                              >
                                Chrome udvidelse
                              </a>
                            </div>
                          )}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          onClick={() => {
                            window.open(item.url, "_blank");
                          }}
                        >
                          {item.title}
                        </Button>
                        <Button
                          size="small"
                          onClick={() => {
                            var flipped = [...flippedProjects];
                            flipped.push(item.title);
                            setFlippedProjects(flipped);
                          }}
                        >
                          Mere info
                        </Button>
                      </CardActions>
                    </Card>
                  </div>
                  <div className="flip-card-back">
                    <Typography
                      fontSize={"18px"}
                      textAlign={"center"}
                      mt={"5px"}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      className="detailed-description"
                      fontSize={"12px"}
                      margin={"10px"}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.detailedDescription,
                        }}
                      ></span>
                    </Typography>
                    <Button
                      onClick={() => {
                        setFlippedProjects(
                          flippedProjects.filter(
                            (current) => current !== item.title
                          )
                        );
                      }}
                    >
                      Luk
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </Box>
        <Typography
          mt={"30px"}
          mb={"20px"}
          variant="body2"
          margin={"30px"}
          color="text.secondary"
          textAlign={"center"}
        >
          <Typography display={"flex"} justifyContent={"center"} mb={"10px"}>
            <BuildCircleIcon /> Teknisk info
          </Typography>
          Klik på <b>MERE INFO</b> knapperne for at læse detaljer omkring
          projektet
        </Typography>
      </ProjectWrapper>
    </Box>
  );
};

const ProjectWrapper = styled.div`
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    margin: 10px;
    /* border: 1px solid #f1f1f1; */
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }

  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
  }

  .flipped {
    transform: rotateY(180deg);
  }

  /* Position the front and back side */
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    display: flex;
    flex-direction: column;
  }

  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: white;
    color: black;
  }

  /* Style the back side */
  .flip-card-back {
    color: black;
    transform: rotateY(180deg);
    box-shadow: 2px 2px 4px #888888;
    display: flex;
    text-align: left;

    .detailed-description {
      flex: 1;
    }
  }
`;

export default Projects;
