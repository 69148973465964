import { Typography } from "@mui/material";
import styled from "styled-components";
import Frontpage from "./Components/Frontpage";

function App() {
  return (
    <>
      <Header>
        <img onClick={()=> { window.location.reload() }} src={require("../src/Images/logo.png")} width="50px" />
        <Typography fontWeight={'bold'}>thomasrasmussen.dk</Typography>
      </Header>

      <Frontpage />
    </>
  );
}

const Header = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;

  p{
    margin-left: 10px;
  }
  
`;

export default App;
